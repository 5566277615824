import * as React from "react"
import Seo from "../components/seo2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import Layout from "../components/layout2023"
import DevelopmentAndQA from "../componentsv2/developmentandqa/developmentandqa"
import HeaderV2 from "../componentsv2/headerv2/headerv2"
const DevelopmentAndQa = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo title="Conversion Rate Optimization Experts | 30% Lift Guaranteed" />
    <HeaderV2 version="v2" />
    <DevelopmentAndQA />
    <FooterV2 version="v2" />
  </Layout>
)

export default DevelopmentAndQa
